import "./ceo.scss"
import * as React from "react"
import {  GatsbyImage, getImage } from "gatsby-plugin-image"
import { Container, Col, Row } from 'react-bootstrap'
import { useTranslation } from "gatsby-plugin-react-i18next"
import { useStaticQuery, graphql } from 'gatsby';

const Ceo = ({ className }) => {

    const { t, i18n } = useTranslation("common", { keyPrefix: "Ceo" })

    const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "careers" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
            name
          }
        }
      }
    }
  `);
    const image = data.allFile.edges.find(
        edge => edge.node.name === `rosalba-ceo-bts-${i18n.language.toLowerCase()||"en"}`
    );
    const imageData = getImage(image.node);
    return (
        <section className={(className ? className + " " : "") + "section-ceo"}>
            <Container fluid="xl">
                <Row>
                    <Col className="col-img" md={6}>
                        <GatsbyImage 
                            className="img-border-radius"
                            layout="fullWidth"
                            alt="Rosalba Reynoso"
                            image={imageData}
                            formats={["auto", "webp", "avif"]}
                        />
                    </Col>
                    <Col className="col-message" md={6}>
                        <div className="message-wrapper">
                            <p className="message">
                                “{t("I do not want Blue Trail to be the biggest company around, I want it to be the best one!")}"
                            </p>
                            <p className="name">
                                Rosalba Reynoso
                            </p>
                            <p className="position">
                                {t("Co-founder and CEO")}
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Ceo
