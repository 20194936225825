import { graphql } from "gatsby"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import CareersHeader from "../../components/careers/careersheader"
import FindDreamJob from "../../components/careers/finddreamjob"
import LocationsSection from "../../components/careers/locationssection"
import WhoBTS from "../../components/careers/whobts"
import WhyBTS from "../../components/careers/whybts"
import Ceo from "../../components/culture/ceo"
import AboutInternshipBrief from "../../components/internship/aboutinternshipbrief"
import Layout from "../../components/layout/layout"
import ReferAFriend from "../../components/refer/referafriend"
import Seo from "../../components/seo"

const CareersPage = () => {
    const { i18n } = useI18next()
    const { t } = useTranslation()
    const pageTitle = t("title")
    const pageDescription = t("Join our Team, Find Your Dream Job")
    const lang = 'EN'
    const meta = []
    //const backgroundImage = "../../images/refer/bg-world@3x.png"
    const breadcrumbMap = [
        { title: "Careers", url: "#" }
    ]

    return (
        <>
            <Layout lang={i18n.resolvedLanguage} breadcrumbMap={breadcrumbMap}  >
                <Seo title={pageTitle} lang={i18n.resolvedLanguage} description={pageDescription} meta={meta} />
                <div className="max-container-pages">
                    <CareersHeader
                        title={t("Join Our Team")}
                        subtitle={t("Find Your Dream Job")}
                        button={t("Search")}
                        link={t("See all jobs")}
                        placeholder={t("What job are you looking for today")}></CareersHeader>
                    <WhoBTS></WhoBTS>
                    <WhyBTS></WhyBTS>
                    <ReferAFriend></ReferAFriend>
                    <AboutInternshipBrief title={t("Join our Talent Network")}></AboutInternshipBrief>
                    <Ceo></Ceo>
                    <LocationsSection></LocationsSection>
                    <FindDreamJob></FindDreamJob>
                </div>
            </Layout>
        </>
    )
}

export default CareersPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "careers"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`