import "./careersheader.scss"
import * as React from "react"
import { Link, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Form, Button, Container } from "react-bootstrap"
import { useTranslation } from "gatsby-plugin-react-i18next"
import logoEn from "../../images/icons/i-am-bts-en.svg"
import logoEs from "../../images/icons/i-am-bts-es.svg"

const CareersHeader = ({ title = "Join Our Team",
    subtitle = "Find Your Dream Job",
    placeholder = "What job are you looking for today?",
    button = "Search",
    link = "See all jobs" }) => {
    const [keyword, setKeyword] = React.useState('');
    const { t } = useTranslation("careers");
    title = t(title);
    subtitle = t(subtitle);
    const { i18n } = useTranslation()
    function handleSearchClick(event) {
        event.preventDefault();
        navigate("/careers/jobs/?keyword=" + keyword.replace(' ', '%20'));
    }

    function handleKeywordChange(event) {
        setKeyword(event.target.value);
    }

    const logo = { en: logoEn, es: logoEs }

    return (
        <>
            <section className="section-careers-header">
                <Container fluid="xl">
                    <div className="container-call">
                        <h1>
                            {title}
                        </h1>
                        <h2>
                            {subtitle}
                        </h2>
                        <div className="wrapper-search-form">
                            <Form onSubmit={(e) => handleSearchClick(e)}>
                                <Form.Group controlId="formSearchCareer">
                                    <Form.Control
                                        type="input"
                                        placeholder={placeholder}
                                        onChange={(e) => handleKeywordChange(e)}
                                        value={keyword}
                                    />
                                    <Button
                                        className="btn-search"
                                        variant="success"
                                        type="submit"
                                    >
                                        {button}
                                    </Button>
                                </Form.Group>
                            </Form>
                        </div>
                        <Link to="/careers/jobs/" className="job-link">
                            {link}
                        </Link>
                    </div>
                    <Link className="goto-search-arrow d-none d-md-block" to="#jobs">
                        <span className="icon-arrow-down arw-wh-blue"> </span>
                    </Link>
                </Container>
                <img
                    className="bts-logo-medium logo-bts-medium"
                    //layout="fullWidth"
                    width={170}
                    alt="Who we are"
                    src={logo[i18n.language.toLowerCase() || "en"]}
                />
                <img
                    className="bts-logo logo-bts-4k"
                    //layout="fullWidth"
                    width={376}
                    alt="Who we are"
                    src={logo[i18n.language.toLowerCase() || "en"]}
                />
            </section>
        </>
    )
}
export default CareersHeader
