import "./whobts.scss"
import * as React from "react"
import { Col, Row } from 'react-bootstrap'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from 'gatsby';
import { Link, useTranslation } from "gatsby-plugin-react-i18next"

const WhoBTS = () => {
    const { t, i18n } = useTranslation()
    const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "careers" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
            name
          }
        }
      }
    }
  `);

    const images = {
        "who-we-are": null,
        "who-we-are-full": null,
        "who-we-are-laptop": null
    }

    Object.keys(images).forEach(key => {
        const image = images[key] = data.allFile.edges.find(
            edge => edge.node.name === `${key}-${i18n.language.toLowerCase() || "en"}`
        );
        images[key] = getImage(image.node);
    })

    return (
        <section className="section-careers-who">
            <Row className="flex-reverse">
                <Col className={i18n.language.toLowerCase() === "es" ? "col-img es-bg" : "col-img"} lg={6}>
                    <GatsbyImage
                        className="d-md-none"
                        layout="fullWidth"
                        //height={580}
                        alt="Who we are"
                        image={images["who-we-are"]}
                        formats={["auto", "webp", "avif"]}
                    />
                    <GatsbyImage
                        className="d-none d-md-block d-lg-none"
                        layout="fullWidth"
                        //height={580}
                        alt="Who we are"
                        image={images["who-we-are-full"]}
                        formats={["auto", "webp", "avif"]}
                    />
                    <GatsbyImage
                        className="d-none d-lg-block d-xl-none"
                        fit="fill"
                        //height={580}
                        alt="Who we are"
                        image={images["who-we-are-laptop"]}
                        formats={["auto", "webp", "avif"]}
                    />
                </Col>
                <Col className="col-text" lg={6}>
                    <h4 className="header-square green md-blue md-large">{t("Who We Are")}</h4>
                    <p>
                        {t("We are a San Francisco based software development company, with production centers in LATAM")}
                    </p>
                    <p>
                        {t("We specialize in creating sophisticated WEB solutions")}
                    </p>
                    <p>
                        {t("Our clients are technology companies ranging from ambitious start-ups to industry leaders like Samsung")}
                    </p>
                    <p>
                        {t("Our values are essential to us")}
                    </p>
                    <p>
                        {t("We are long-term oriented, and we like our employees to grow with us")}
                    </p>
                    <Link className="link-dark-blue-btn w-full" to="/about/#ourculture">
                        {t("Learn More About Culture")}
                    </Link>
                </Col>
            </Row>
        </section>
    )
}

export default WhoBTS
