import "./aboutinternshipbrief.scss"
import * as React from "react"
import { Container, Col, Row } from 'react-bootstrap'
import ButtonApplyNow from "../layout/buttonapplynow"
import { useTranslation } from "gatsby-plugin-react-i18next"

const AboutInternshipBrief = ({ className, title = "Join our Talent Network" }) => {
    const { t } = useTranslation("common", { keyPrefix:"AboutInternshipBrief"})
    title = t("Join our Talent Network");
    return (
        <section className={(className ? className + " " : "") + "section-aboutinternship"}>
            <Container fluid="xl">
                <Row>
                    <Col className="col-title" md={6}>
                        <h4 className="header-square white">{title}</h4>
                    </Col>
                    <Col className="col-message" md={6}>
                        <p>
                            {t("Tell us more about yourself and we will keep you up-to-date regarding upcoming events and career opportunities that match your interests")}
                        </p>
                        <ButtonApplyNow variant="link" buttonClassName="link-grey-btn w-full f-white" arrow={false} title={[t("Let's connect"), t("Join our Talent Network")]}>
                            {t("Join the network now")}
                        </ButtonApplyNow>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default AboutInternshipBrief
